// import logo from './logo.svg';
import './App.css';
// import NavbarHome from './components/navbarHome';
// import Artists from './components/DiscoverArtists'
// import GsevenRelease from './components/GsevenRelease';
// import Footer from './components/Footer';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/Home';
import SubmitMusic from './pages/SubmitMusic';
// import SubmitMusic2 from './pages/SubmitMusic2';
import Network from './pages/Network';
import ComingSoon from './pages/ComingSoon';
import ErrorPage from './pages/404';

function App() {
  return (
    <Routes>
      <Route path='/' element={<HomePage/>} />  
      <Route path='/submit' element={<SubmitMusic/>} />
      {/*<Route path='/submit2' element={<SubmitMusic2/>} />*/}
      <Route path='/networks' element={<Network/>} />  
      <Route path='/coming-soon' element={<ComingSoon/>} />  
      <Route path='*' element={<ErrorPage/>} /> 
    </Routes>
  );
}

export default App;
