import { useState } from 'react'
import { Dialog, Popover } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
// import { PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'

// const products = [
//   { name: 'Analytics', description: 'Get a better understanding of your traffic', href: '#', icon: ChartPieIcon },
//   { name: 'Engagement', description: 'Speak directly to your customers', href: '#', icon: CursorArrowRaysIcon },
//   { name: 'Security', description: 'Your customers’ data will be safe and secure', href: '#', icon: FingerPrintIcon },
//   { name: 'Integrations', description: 'Connect with third-party tools', href: '#', icon: SquaresPlusIcon },
//   { name: 'Automations', description: 'Build strategic funnels that will convert', href: '#', icon: ArrowPathIcon },
// ]
// const callsToAction = [
//   { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
//   { name: 'Contact sales', href: '#', icon: PhoneIcon },
// ]

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

export default function NavbarTwo() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <>
    <header className="absolute w-full">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5 flex items-center text-white">
            <span className="sr-only">Your Company</span>
            <img className="h-[52px] w-auto" src="https://ik.imagekit.io/k64/g7/g7-logo.png" alt="" />
            <span className='font-segoe text-[#FF9F1C] pl-[6px] sm:text-[15px] text-[12px]' style={{fontWeight:"bold", letterSpacing:"0.33em"}}>Gseven Records</span>
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" style={{color:'white'}}/>
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <a href="/" className="font-inter text-sm text-interi font-semibold leading-6 text-white">
            HOME
          </a>
          <a href="/networks" className="font-inter text-sm text-interi font-semibold leading-6 text-white">
            NETWORKS
          </a>
          <a href="/submit" className="font-inter text-sm font-semibold leading-6 text-white">
            SUBMIT
          </a>
          <a href="/coming-soon" className="font-inter text-sm font-semibold leading-6 text-white">
            MERCH
          </a>
          <a href="/" className="font-inter text-sm font-semibold leading-6 text-white">
            ABOUT US
          </a>
        </Popover.Group>
      </nav>
      {/* nav mob */}
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-[rgba(0,0,0,1)] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5 flex items-center">
              <span className="sr-only">Your Company</span>
              <img className="h-[52px] w-auto" src="https://ik.imagekit.io/k64/g7/g7-logo.png" alt="" />
              <span className='font-segoe text-[#FF9F1C] pl-[6px]' style={{fontSize:"12px", fontWeight:"bold", letterSpacing:"0.33em"}}>Gseven Records</span>
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" style={{color:"white"}} aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-8 py-6 h-[70vh] flex flex-col items-center justify-center">
                <a
                  href="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                >
                  HOME
                </a>
                <a
                  href="/networks"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                >
                  NETWORKS
                </a>
                <a
                  href="/submit"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                >
                  SUBMIT
                </a>
                <a
                  href="/coming-soon"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                >
                  MERCH
                </a>
                <a
                  href="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                >
                  ABOUT US
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
    
    </>

    

  )
}