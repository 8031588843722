import svg from '../Media/404 error with a landscape-bro.svg';
function ErrorPage() {
    return ( 
        <>
        <div className="flex flex-col justify-center items-center h-screen">
            <img src={svg} className='h-[50vh] w-auto'/>
            <div className='flex flex-col text-black font-inter items-center gap-[12px]'>
                <span className='text-[22px]'>PAGE NOT FOUND</span>
                <div className='text-base text-center max-sm:mx-[8px]'>
                    <span>THE LINK YOU FOLLOWED IS PROBABLY BROKEN OR THE PAGE HAS BEEN REMOVED.</span>
                </div>
                <div className='flex'>
                <a href='/' className='underline text-xl'>Return To Home Page</a>
                </div>
            </div>
        </div>
        </>
     );
}

export default ErrorPage;