function Artists() {
    return (
        <>
        <section className="bg-gradient scroll-snap">
            <div className="h-screen">
                <div className='flex flex-col items-center justify-center pt-8 h-[90vh]'>
                <img src='https://ik.imagekit.io/k64/g7/g7-logo.png' alt='hero-bg' className='sm:w-[420px] w-[300px] hero-img'/>
                    <div className='text-[#4A4A4A] text-center font-aclonica flex flex-col text-[20px] pt-[45px]'>
                    <span>Unleash the</span><span>Power of Music</span>
                    </div>
                </div>
                <div className="flex items-center justify-center w-full bounce">
                <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3.25C12.4142 3.25 12.75 3.58579 12.75 4L12.75 18.1893L17.4697 13.4697C17.7626 13.1768 18.2374 13.1768 18.5303 13.4697C18.8232 13.7626 18.8232 14.2374 18.5303 14.5303L12.5303 20.5303C12.3897 20.671 12.1989 20.75 12 20.75C11.8011 20.75 11.6103 20.671 11.4697 20.5303L5.46967 14.5303C5.17678 14.2374 5.17678 13.7626 5.46967 13.4697C5.76256 13.1768 6.23744 13.1768 6.53033 13.4697L11.25 18.1893L11.25 4C11.25 3.58579 11.5858 3.25 12 3.25Z" fill="#bbff00"></path> </g></svg>
                </div>
            </div>
            <div className="h-screen">
                <div className="h-1/3 flex flex-col items-center justify-center">
                    <span className="text-[#FF9F1C] lg:text-[16px] md:text-[14px] sm:text-[12px] text-[10px] font-inter lg:max-w-[990px] md:max-w-[720px] sm:max-w-[520px] max-w-[320px]  text-center">Elevate your sound with Gseven Records, where Indian artists find a home for their music to soar internationally. We not only release your beats worldwide but also promote a variety of genres in the form of lyrical videos on our YouTube channel. Join us in creating a symphony of stories that captivate hearts around the globe.</span>
                    <button className="sm:mt-[20px] mt-[16px] flex items-center justify-center glow-on-hover max-md:text-[14px] sm:h-10 h-8 gap-[4px]">
                        <span className="">Discover</span>
                        <span style={{fontWeight:"bold"}}>NOW</span>
                    </button>
                </div>

                <div className="lg:h-2/3 h-screen bg-[#000]">
                    <div className="xl:max-w-[1200px] max-w-[990px] mx-auto text-white flex flex-col justify-center items-center h-full">
                        <div className="lg:pb-[1em] pb-[0.2em] max-md:pb-[2.5em] text-[#4A4A4A] sm:text-[40px] text-[32px] font-bold font-montserrat" style={{lineHeight:"100%"}}>
                            GSEVEN ARTIST
                        </div>

                        <div className="lg:flex  gap-[7em]">
                            <a href="https://linktr.ee/julkaryan" className="relative overflow-hidden flex lg:flex-col justify-center items-center text-center md:max-lg:w-[420px] max-lg:w-[320px] justify-between">
                                <img className="artist-hover xl:w-[300px] md:w-[200px] w-[150px] xl:h-[300px] md:h-[200px] h-[150px] rounded-[33px] object-cover" src="https://ik.imagekit.io/k64/g7/artists/ARYAN.jpeg" alt="artist-cover"/>
                                <span className="font-syne uppercase pt-[10px] md:w-[200px] w-[150px]">ARYAN JULKA</span>
                            </a>

                            <a href="https://linktr.ee/A.T.G._Bad_feat.TOBY" className="relative overflow-hidden flex lg:flex-col justify-center items-center text-center md:max-lg:w-[420px] max-lg:w-[320px] justify-between">
                                <img className="artist-hover order2 h-auto xl:w-[300px] md:w-[200px] w-[150px] xl:h-[300px] md:h-[200px] h-[150px] rounded-[33px] object-cover" src="https://ik.imagekit.io/k64/g7/artists/ATG_.jpg" alt="artist-cover"/>
                                <span className="order1 font-syne uppercase pt-[10px] md:w-[200px] w-[150px]">A.T.G</span>
                            </a>

                            <a href="https://killseph.streamlink.to/YOUREMINE" className="relative overflow-hidden flex lg:flex-col justify-center items-center text-center md:max-lg:w-[420px] max-lg:w-[320px] justify-between">
                                <img className="artist-hover h-auto xl:w-[300px] md:w-[200px] w-[150px] xl:h-[300px] md:h-[200px] h-[150px] rounded-[33px] object-cover" src="https://ik.imagekit.io/k64/g7/artists/killseph_.jpg" alt="artist-cover"/>
                                <span className="font-syne uppercase pt-[10px] md:w-[200px] w-[150px]">KILLSEPH</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </section>
        </>
    )
}
export default Artists;