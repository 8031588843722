import React from "react";
import NavbarTwo from "../components/navbartwo";
import Footer from "../components/Footer";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";


function SubmitMusic() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const toastifySuccess = () => {
    toast.success("Message sent!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

  const toastifyError = () => {
    toast.error("Failed, try again!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback error",
      toastId: "notifyToast",
    });
  };

  const onSubmit = async (data) => {
    const submitButton = document.getElementById('sbmtBtn');
    submitButton.classList.add('hidden');

    const submitButtonShow = document.getElementById('sbmtBtnShow');
    submitButtonShow.classList.add('!flex');

    const { name, stageName, email, details, music } = data;

    if (!name || !email || !details || !music) {
      return;
    }

    try {
      const templateParams = {
        name,
        stageName,
        email,
        details,
        music,
      };

      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        // "g7emails",
        // "template_1shnhfr",
        templateParams,
        process.env.REACT_APP_USER_ID
        // "kAbCEIu7yzqluWKE-"
      );
      reset();
      toastifySuccess();

      submitButtonShow.classList.add('hidden');
      submitButtonShow.classList.remove('!flex');
      submitButton.classList.add('block');
      submitButton.classList.remove('hidden');
    } catch (e) {
      console.log(e);
      toastifyError();

      submitButtonShow.classList.add('hidden');
      submitButtonShow.classList.remove('!flex');
      submitButton.classList.add('block');
      submitButton.classList.remove('hidden');
    }
  };

  return (
    <>
      <div className="relative z-10 mb-[80px]">
        <NavbarTwo />
        <section className="md:h-[130vh] h-[100vh] bg-gradient pt-[180px] font-inter ">
          <div className="flex flex-col items-center max-lg:container max-lg:mx-auto max-lg:text-center">
            <div className="text-white">
              <span className="opacity-90 lg:text-[82px] md:text-[62px] sm:text-[52px] text-[24px] font-bold text-center">
                SUBMIT YOUR MUSIC
              </span>
            </div>
          </div>
          <div className="container mx-auto text-white pt-[30px]">
            <div className="flex items-center justify-center">
              <div className="w-auto">
                <div className="p-[40px] max-md:hidden">
                  <div className="flex gap-[18px] items-center">
                    <p className="text-[30px] font-semibold">Let's Connect</p>
                    <img
                    alt="logo small"
                      src="https://ik.imagekit.io/k64/g7/g7-logo.png"
                      className="w-[32px] h-[32px]"
                    />
                  </div>
                  <p className="opacity-80 text-base">
                    Let's align our constellations! Reach out and let the magic
                    of collaboration illuminate our skies.
                  </p>
                </div>

                <div className="pr-[40px] pl-[40px]">
                  <form
                    className="flex flex-col gap-[14px]"
                    id="contact-form"
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                  >
                    <div className="flex gap-[14px] columns-2">
                      <input
                        className={`sm:px-2.5 sm:py-3 px-1.5 py-2 max-sm:text-[14px] rounded-[5px] bg-white/5 border ${
                          errors.name ? "border-red-500" : "border-white/20"
                        } w-full`}
                        type="text"
                        name="name"
                        placeholder="Artist Legal Name"
                        {...register("name", {
                          required: {
                            value: true,
                            message: "Please enter your name*",
                          },
                        })}
                      />
                      <input
                        className={`sm:px-2.5 sm:py-3 px-1.5 py-2 max-sm:text-[14px] rounded-[5px] bg-white/5 border ${
                          errors.stageName ? "border-red-500" : "border-white/20"
                        } w-full`}
                        type="text"
                        name="stageName"
                        placeholder="Artist Stage Name"
                        {...register("stageName", {
                          required: {
                            value: true,
                            message: "Please enter your stage name*",
                          },
                        })}
                      />
                    </div>
                    <input
                      className={`sm:px-2.5 sm:py-3 px-1.5 py-2 max-sm:text-[14px] rounded-[5px] bg-white/5 border ${
                        errors.email ? "border-red-500" : "border-white/20"
                      }`}
                      type="text"
                      name="email"
                      placeholder="Email"
                      {...register("email", {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]{3,}\.[a-zA-Z0-9-]+/,
                      })}
                    />
                    <div className="flex gap-[14px] columns-2">
                      <input
                        className={`sm:px-2.5 sm:py-3 px-1.5 py-2 max-sm:text-[14px] rounded-[5px] bg-white/5 border ${
                          errors.music ? "border-red-500" : "border-white/20"
                        } w-8/12`}
                        type="text"
                        name="music"
                        placeholder="Music Demo [Link &#128279;]"
                        {...register("music", {
                          required: {
                            value: true,
                            message: "Please enter your music demo link*",
                          },
                        })}
                      />
                      <div className="sm:px-2.5 sm:py-3 px-1.5 py-2 max-sm:text-[14px] rounded-[5px] bg-white/5 border border-white/20 w-4/12">
                        Record Label
                      </div>
                    </div>
                    <textarea
                      className={`sm:px-2.5 sm:py-3 px-1.5 py-2 max-sm:text-[14px] rounded-[5px] bg-white/5 border ${
                        errors.details ? "border-red-500" : "border-white/20"
                      }`}
                      type="text"
                      row="6"
                      placeholder="More Info"
                      name="details"
                      {...register("details", {
                        required: {
                          value: true,
                          message: "Please enter more info*",
                        },
                      })}
                    />
                    {errors.name ||
                    errors.stageName ||
                    errors.email ||
                    errors.details ||
                    errors.music ? (
                      <span className="errorMessage text-red-500">
                        Please fill all the fields correctly*
                      </span>
                    ) : null}

                    <button
                      id="sbmtBtn"
                      type="submit"
                      className="px-2.5 py-3 rounded-[5px]"
                      style={{
                        background:
                          "linear-gradient(to right, #763af5 0%, #a604f2 117.18%)",
                      }}
                    >
                      Send It
                    </button>
                    
                    <button
                      id="sbmtBtnShow"
                      type="submit"
                      className="px-2.5 py-3 rounded-[5px] hidden items-center justify-center"
                      style={{
                        background:
                          "linear-gradient(to right, #763af5 0%, #a604f2 117.18%)",
                      }}
                    >
                      Send It 
                    <svg className="animate-spin -mr-1 sm:ml-3 ml-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default SubmitMusic;
