import React from 'react'
import NavbarTwo from '../components/navbartwo'
import Footer from '../components/Footer'
// import { SocialIcon } from 'react-social-icons'

function ComingSoon() {
  return (
    <>
    <div className="relative z-10 mb-[80px]">
    <NavbarTwo/>
    <div className="h-screen pt-[120px] bg-black /*bgImg*/">
        <div className="container mx-auto max-w-6xl text-white flex h-[75vh] items-center lg:px-[24px] max-lg:justify-center">
            
            <div className='w-2/5 max-lg:hidden'>
                <img className='' src='https://ik.imagekit.io/k64/g7/g7-logo.png?updatedAt=1715706033839'/>
            </div>
            <div className='flex flex-col lg:w-3/5 max-w-[640px]'>
                <span className="sm:text-7xl text-4xl font-chopsic">COMING SOON</span>
                <span className="sm:text-xl text-[14px] font-segoe">This Page Is Under Construction, <br/>Follow Us On Our Socials For Latest Updates.</span>

                {/* <div className='sm:mt-16 mt-6'>
                    <span>Stay Connected</span>

                    <div className='flex max-sm:flex-col max-sm:gap-y-[14px] gap-x-[14px] pt-[12px]'>
                    <SocialIcon as="a" style={{width:"40px", height:"40px"}} target="_blank" rel="noreferrer" url="https://open.spotify.com/user/o1vwv4ar2q29l983cz9ov4si1?si=bec9cb7484174c10" bgColor="white" fgColor="black" />
                    <SocialIcon as="a" style={{width:"40px", height:"40px"}} target="_blank" rel="noreferrer" url="https://facebook.com/gsevenrecords" bgColor="white" fgColor="black" />
                    <SocialIcon as="a" style={{width:"40px", height:"40px"}} target="_blank" rel="noreferrer" url="https://instagram.com/gsevenrecords" bgColor="white" fgColor="black" />
                    <SocialIcon as="a" style={{width:"40px", height:"40px"}} target="_blank" rel="noreferrer" url="https://youtube.com/@GsevenRecordsyt" bgColor="white" fgColor="black" />
                    </div>

                </div> */}
            </div>
        </div>
    </div>
    </div>
    <Footer/>
    </>
  )
}

export default ComingSoon