function Footer(){
    return (
        <>
        <footer className="z-[1] fixed bottom-[0] h-[330px] w-full" style={{fontSize:"98px"}}>
            <div className="footer flex items-center justify-center sm:gap-8 gap-6 sm:pt-[250px] pt-[275px]">
                <a href="https://www.instagram.com/gsevenrecords/"><img className="sm:w-auto w-[30px]" src="https://ik.imagekit.io/k64/g7/socials/insta.svg" alt="ig-logo"/></a>
                <a href="https://open.spotify.com/user/o1vwv4ar2q29l983cz9ov4si1"><img className="sm:w-auto w-[30px]" src="https://ik.imagekit.io/k64/g7/socials/Spotify.svg" alt="spotify-logo"/></a>
                <a href="https://www.youtube.com/@GsevenRecordsyt/"><img className="sm:w-auto w-[30px]" src="https://ik.imagekit.io/k64/g7/socials/YouTube.svg" alt="yt-logo"/></a>
                <a href="https://facebook.com/gsevenrecords"><img className="sm:w-auto w-[30px]" src="https://ik.imagekit.io/k64/g7/socials/Facebook.svg" alt="fb-logo"/></a>
                <a href="https://soundcloud.com/gseven-records"><img className="sm:w-auto w-[30px]" src="https://ik.imagekit.io/k64/g7/socials/soundcloud.svg" alt="soundcloud-logo"/></a>
            </div>
        </footer>
        </>
    )
}
export default Footer;