import NavbarTwo from "../components/navbartwo";
import Artists from "../components/DiscoverArtists";
import GsevenRelease from "../components/GsevenRelease";
import Footer from "../components/Footer";

function HomePage() {
    return (
    <>
        <div className="relative z-10 mb-[80px]">
            <NavbarTwo/>
            <Artists/>
            <GsevenRelease/>
        </div>
        <Footer/>
    </>    
)}

export default HomePage;