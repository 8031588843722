import React, { useState, useEffect, useRef }  from 'react';
import CountUp from 'react-countup';

function GsevenRelease() {
    const [isVisible, setIsVisible] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const containerRef = useRef(null);
    const onStart = () => {setLoading(true)};
    const onEnd = () => {setLoading(false)};

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Adjust this threshold as needed
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        }, options);

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        // Cleanup the observer when component unmounts
        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, []);


    const containerProps = {
    'aria-busy': loading
    };

    return (
        <>
        <section className="lg:h-screen bg-[#000000]">
            <div className="lg:h-2/5 flex flex-col bg-[#011627] lg:max-lg:py-[4em] py-[1em]  py-3 justify-center items-center max-lg:mt-[14em]">
                <span className="text-[#FF9F1C] text-[40px] font-montserrat lg:mx-[300px] font-bold text-center max-sm:text-[20px]">GSEVEN  PLAYLIST</span>
                <div className="sm:grid sm:grid-cols-3 max-sm:flex max-sm:flex-col xl:gap-[14em] lg:gap-[10em] sm:gap-[1.5em] sm:max-lg:py-[2em] py-[1em]">
                    
                    <a className="flip-card" href='https://open.spotify.com/playlist/70JGeLOPQMYiFX47QqTOj0' target="_blank" rel="noreferrer">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img className="lg:w-full lg:h-full w-[140px] h-auto" src="https://ik.imagekit.io/k64/g7/Albums/Tiktok.jpg" alt=""/>
                            </div>
                            <div class="flip-card-back flex items-center justify-center">
                                <span className="font-syne lg:text-white text-white font-bold sm:text-[20px] text-[14px] max-lg:pt-[8px]">Viral TikTok</span>
                            </div>
                        </div>
                    </a>
                        

                    <a className="flip-card"  href='https://open.spotify.com/playlist/32dWtZx70XBsMjfWq7hHhe' target="_blank" rel="noreferrer">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img className="lg:w-full lg:h-full w-[140px] h-auto" src="https://ik.imagekit.io/k64/g7/Albums/Rap.jpg" alt=""/>
                            </div>
                            <div class="flip-card-back flex items-center justify-center">
                                <span className="font-syne lg:text-white text-white font-bold sm:text-[20px] text-[14px] max-lg:pt-[8px]">Rap & Hip-Hop</span>
                            </div>
                        </div>
                    </a>


                    <a className="flip-card" href='https://open.spotify.com/playlist/1RK2lUCoNEysPEur1tvW9a' target="_blank" rel="noreferrer">
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img className="lg:w-full lg:h-full w-[140px] h-auto" src="https://ik.imagekit.io/k64/g7/Albums/Pop.jpg" alt=""/>
                            </div>
                            <div class="flip-card-back flex items-center justify-center">
                                <span className="font-syne lg:text-white text-white font-bold sm:text-[20px] text-[14px] max-lg:pt-[8px]">Viral Pop</span>
                            </div>
                        </div>
                    </a>

                </div>
            </div>

            <div className="lg:h-3/5 sm:h-[70vh] h-[60vh] md:mx-[160px] text-white flex items-center max-lg:flex-col max-lg:justify-center">
                <div className="text-[#4A4A4A]  max-sm:mb-[2em] sm:text-[40px] text-[20px] font-bold font-montserrat" style={{lineHeight:"100%"}}>
                <span className='max-lg:hidden'>GSEVEN<br/>RELEASE</span>
                <span className='lg:hidden'>GSEVEN RELEASE</span>
                </div>


                <div className="lg:w-full flex justify-end">
                    <div className="flex flex-col justify-center text-center lg:bg-white xl:w-[600px] xl:h-[300px] h-[220px] sm:w-[470px] w-[320px] rounded-[72px]">
                        <div className="flex max-lg:flex-col items-center sm:max-lg:pt-[2em] max-sm:pt-[4em ]">
                            <div className="w-1/2 flex justify-center">
                                <img src="https://ik.imagekit.io/k64/g7/g7releases01.png" className="rounded-[72px] xl:w-[260px] xl:h-[260px] sm:w-[200px] sm:h-[200px] w-[160px] h-[160px] bg-black" alt="g7logo"/>
                            </div>  
                            
                            <div className="w-1/2 flex items-center max-lg:justify-center">
                                <div className="font-syne lg:text-left text-center text-white lg:text-black xl:text-[32px] text-[22px] font-bold" ref={containerRef}>
                                {isVisible && (
                                    <>
                                    <span className=''><CountUp end={24} duration="3" onStart={onStart} onEnd={onEnd} containerProps={containerProps} /> SONGS</span><br/>
                                    <span className='max-sm:whitespace-nowrap'><CountUp end={1702259} duration="6" onStart={onStart} onEnd={onEnd} containerProps={containerProps} /> VIEWS</span>
                                    </>
                                )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    
            </div>

        </section>
        </>
    )
}
export default GsevenRelease;