import NavbarTwo from "../components/navbartwo";
import Footer from "../components/Footer";
const items = [
    {
        channel:"Unique Sound",
        img:"https://ik.imagekit.io/k64/g7/Channels/UniqueSound.jpg",
        link:"https://www.youtube.com/@UniqueSoundYT"
    },
    {
        channel:"Lazy Fox",
        img:"https://ik.imagekit.io/k64/g7/Channels/LazyFox.jpg",
        link:"https://www.youtube.com/@LazyFoxYT"
    },
    {
        channel:"Lyric Camp",
        img:"https://ik.imagekit.io/k64/g7/Channels/LCyt.jpg", 
        link:"https://www.youtube.com/@lyriccamp"
    },
    {
        channel:"Rappers List",
        img:"https://ik.imagekit.io/k64/g7/Channels/Rappers%20List.jpg",
        link:"https://www.youtube.com/@RappersListYT"
    },
    {
        channel:"Our Memories Tonight",
        img:"https://ik.imagekit.io/k64/g7/Channels/omt.jpg",
        link:"https://www.youtube.com/@ourmemoriestonightYT"
    },
    {
        channel:"Rap Fusion",
        img:"https://ik.imagekit.io/k64/g7/Channels/RapFusion.jpg", 
        link:"https://www.youtube.com/@RapFusionYT"
    },
    {
        channel:"RegionalOnly",
        img:"https://ik.imagekit.io/k64/g7/Channels/RegionalOnly.jpg",
        link:"https://www.youtube.com/@RegionalOnlyYT"
    },
    {
        channel:"Rap Saga",
        img:"https://ik.imagekit.io/k64/g7/Channels/RapSaga.jpg",
        link:"https://www.youtube.com/@RapSagaYT"
    },
    {
        channel:"LatinVerse",
        img:"https://ik.imagekit.io/k64/g7/Channels/LatinVerse.jpg",
        link:"https://www.youtube.com/@LatinVerseLyrics"
    }
]


function Network() {
    return ( 
        <>

        <div className="relative z-10 mb-[80px]">
            <NavbarTwo/>
            <div className="bg-black">
                <div className="container mx-auto h-auto max-w-6xl sm:py-[180px] py-[120px]">

                    <div className="flex justify-center sm:mb-[80px] mb-[40px]">
                        <span className="text-white sm:text-[40px] text-[24px] font-bold font-montserrat">GSEVEN NETWORKS</span>
                    </div>
                    <div className="grid grid-cols-3 text-white justify-items-center md:gap-y-[80px] sm:gap-y-[60px] gap-y-[40px]">
                        {items.map((d) => (
                            <>
                            <div className="flex flex-col md:gap-[24px] sm:gap-[20px] gap-[14px]">
                                <a href={d.link} target="_blank" rel="noreferrer" className="flex justify-center">
                                <img src={d.img} className="md:w-[150px] md:h-[150px] sm:w-[110px] sm:h-[110px] w-[80px] h-[80px] rounded-full border-4 border-white border-solid"/>
                                </a>
                                <div className="text-center md:text-[20px] sm:text-[16px] text-[14px]">{d.channel}</div>
                            </div>
                            </>
                        ))}
                        <div className="flex flex-col col-span-3 md:gap-[24px] sm:gap-[20px] gap-[14px]">
                                <a href="" target="_blank" rel="noreferrer" className="flex justify-center">
                                <img src="https://ik.imagekit.io/k64/g7/Channels/bassadventure.jpg" className="md:w-[150px] md:h-[150px] sm:w-[110px] sm:h-[110px] w-[80px] h-[80px] rounded-full border-4 border-white border-solid"/>
                                </a>
                                <div className="text-center md:text-[20px] sm:text-[16px] text-[14px]">Bass Adventure</div>
                        </div>

                    </div>

                </div>
            </div>

        </div>
        <Footer/>
        </>
     );
}

export default Network;